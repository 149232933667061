import arrowIcon from "../../assets/images/chevron_circle.png";
import styles from "./ImgGallery.module.css";
import GalleryPhoto from "../galleryPhoto/GalleryPhoto";
import img1 from "../../assets/photos/car_far.webp";
import img2 from "../../assets/photos/car_near.webp";
import img3 from "../../assets/photos/car_with_camera.webp";
import img4 from "../../assets/photos/dirt_bike.webp";
import img5 from "../../assets/photos/dirt_bike_with_camera.webp";
import img6 from "../../assets/photos/fork_lift.webp";
import img7 from "../../assets/photos/fork_lift_with_camera.webp";
import img8 from "../../assets/photos/fork_lift_hand.webp";
import img9 from "../../assets/photos/heat_pump.webp";
import img10 from "../../assets/photos/heat_pump_with_camera.webp";
import { useState } from "react";

const ImgGallery = () =>{

    const imageArray = [{id:1, source:img1, alt:"Car Far"}, {id:2, source:img2, alt:"Car Near"}, {id:3, source:img3, alt:"Car With Camera"},
        {id:4, source:img4, alt:"Dirt Bike"},{id:5, source:img5, alt:"Dirt Bike With Camera"},{id:6, source:img6, alt:"Fork Lift"},
        {id:7, source:img7, alt:"Fork Lift With Camera"},{id:8, source:img8, alt:"Fork Lift Hand"},{id:9, source:img9, alt:"Heat Pump"},
        {id:10, source:img10, alt:"Heat Pump With Camera"},
    ];

    const [leftIndex, setLeftIndex] = useState(1);
    const [rightIndex, setRightIndex] = useState(3);
    const [rightArrowEnable, setRightArrowEnable] = useState(true);
    const [leftArrowEnable, setLeftArrowEnable] = useState(false);

    const checkIndex = (photoID) =>{
        if (photoID >= rightIndex + 1){
            return false;
        }
        else if (photoID < leftIndex){
            return false;
        }
        else{
            return true;
        }
    }

    const handleClickLeft = () =>{
        
        const index = leftIndex - 1;
        setRightArrowEnable(true);
        if (leftIndex > 1){
            setLeftIndex((prev)=>prev - 1);
            setRightIndex((prev)=>prev - 1);
        }
        if (index === 1 ){
            setLeftArrowEnable(false);
        };
    };

    const handleClickRight = () =>{
        const index = rightIndex + 1;
        setLeftArrowEnable(true);
        if (rightIndex < imageArray.length){  
            setLeftIndex((prev)=>prev + 1);
            setRightIndex((prev)=>prev + 1);
        };
        if (index === imageArray.length ){
            setRightArrowEnable(false);
        };
    };

    return(
        <div className={styles.container}>
            {leftArrowEnable && <img className={styles.leftArrow} src={arrowIcon} onClick={handleClickLeft}/>}
            {!leftArrowEnable && <img className={styles.leftArrowDisabled} src={arrowIcon}/>}
            <div className={styles.row}>
                {imageArray.map((photo)=>(checkIndex(photo.id) && <GalleryPhoto key={photo.id} photo={photo}/>))}
            </div>
            {rightArrowEnable && <img className={styles.rightArrow} src={arrowIcon} onClick={handleClickRight}/>}
            {!rightArrowEnable && <img className={styles.rightArrowDisabled} src={arrowIcon}/>}

        </div>
    )
};
export default ImgGallery;
import styles from "./PriceRow.module.css";
const PriceRow = ({item}) =>{
    return(
        <div className={styles.container}>
            <p className={item.column1 === "" ? styles.empty1 : styles.column1}>{item.column1 === "" ? "-" : item.column1}</p>
            <p className={item.column2 === "" ? styles.empty2 : styles.column2}>{item.column2 === "" ? "-" : item.column2}</p>
            <p className={item.column3 === "" ? styles.empty3 : styles.column3}>{item.column3 === "" ? "-" : item.column3}</p>
            <p className={item.column4 === "" ? styles.empty4 : styles.column4}>{item.column4 === "" ? "-" : item.column4}</p>
            <p className={styles.column5}>{item.column5}</p>
            <p className={styles.column6}>{item.column6}</p>
        </div>
    );
};
export default PriceRow;
import styles from "./LoadingSpinner.module.css";
import Spinner from "../../assets/other/SpinnerBlack.svg"

const LoadingSpinner = ({showText}) =>{
    return(
        <div className={styles.container}>
            {showText && <p className={styles.loadingText}>Loading Data. Please Wait.</p>}
            <img src={Spinner} className={styles.loadingImg} alt="Loading Spinner"/>
        </div>
    )
}
export default LoadingSpinner;
import styles from "./CustomerMenu.module.css";
import headerLogo from "../../assets/images/full-logo-transparent-white-1950x1142.png";
import userIcon from "../../assets/images/userWhite.svg";
import helpIcon from "../../assets/images/helpWhite.svg";
import backIcon from "../../assets/images/arrow_back_white.svg";
import settingsIcon from "../../assets/images/settingsIcon.png";

const CustomerMenu = ({showBack, showHelp, showUser, showSettings, onGoBack, onHelp, onUser, onSettings, gotoCustomerPage}) =>{
   
    return(
        <div className={styles.container}>
            <div className={styles.left}>
                <div onClick={onGoBack}>
                    {showBack && <img className={styles.logo} src={backIcon} alt="Back Arrow"/>}
                </div>
            </div>
            <div onClick={gotoCustomerPage} className={styles.center}>
                <img className={styles.headerLogo} src={headerLogo} alt="BillyQR Logo"/>
            </div>
            <div className={styles.right}>
                {showHelp && <div onClick={onHelp} ><img className={styles.logo} src={helpIcon} alt="Help"/></div>}
                {showSettings && <div onClick={onSettings}><img className={styles.logo} src={settingsIcon} alt="Help"/></div>}
                {showUser && <div onClick={onUser} ><img className={styles.logo} src={userIcon} alt="User Profile"/></div>}
            </div>
        </div>
    )
}
export default CustomerMenu;

import styles from "./Settings.module.css";
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
import Button from "../../components/button/Button";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useLogout } from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import CustomerMenu from "../../components/customerMenu/CustomerMenu";
import RowButton from "../../components/rowButton/RowButton";
import Title from "../../components/title/Title";

const Settings = () =>{
    const navigation = useNavigate();
    const authCtx = useContext(AuthContext);
    const fullName = authCtx.fullName;

    const {logout} = useLogout();

    const handleGoBack = () =>{
        navigation(-1);
    };

const onHelp=()=>{
    navigation("/help");
};

const handleUser=()=>{
    navigation("/profile")
};

const gotoCustomerPage = () =>{
    navigation("/");
};

const handleSelectCategories=()=>{
    navigation("/default-equipment-categories")
};

const handleSelectEquipmentLists=()=>{
    navigation("/equipment-lists");
};

return(
    <div className={styles.container}>
        <CustomerMenu gotoCustomerPage={gotoCustomerPage} showHelp={true} showBack={true} showUser={true} onUser={handleUser} onGoBack={handleGoBack} onHelp={onHelp}/>
        <Title titleText={"Settings"}/>
        <div className={styles.settingsContainer}>
            <p className={styles.label}>{`Hello, ${fullName}!`}</p>
        </div>
        <div className={styles.itemContainer}>
            <RowButton title={'System Default Equipment Categories'} onClick={handleSelectCategories}/>
        </div>
        <div className={styles.itemContainer}>
            <RowButton title={'Equipment Lists'} onClick={handleSelectEquipmentLists}/>
        </div>
    </div>
)
}
export default Settings;
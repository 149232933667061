import styles from "./MessageBox.module.css";
import TwoBtns from "../twoBtns/TwoBtns";
import Button from "../button/Button";

const MessageBox = ({
  info,
  mainBtnHandler,
  cancelBtnHandler,
  showMain, showCancel
}) => {

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
          <div><p className={styles.info}>{info}</p></div>
          <div >
              <div className={styles.btnContainer}>
                {showMain && <Button onClick={mainBtnHandler} title={'CONTINUE'} enableBtn={showMain}/>}
                {showCancel && <Button title={'CANCEL'} onClick={cancelBtnHandler} enableBtn={showCancel}/>}
              </div>
          </div>
      </div>
    </div>
  );
};
export default MessageBox;

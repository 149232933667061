import { createContext, useState } from "react";
import { auth } from "../firebase/config";
import { readUserFullName } from "../firebase/user";

export const AuthContext = createContext({
    email:'',
    uid:'',
    fullName:'',
    loggedIn:false,
    hasFullName:false,
    confirmedEmail:false,
    login:(email, uid, fullName, emailConfirmed, fullNameSet)=>{},
    logout:()=>{},
    checkEmailConfirmed:()=>{},
    updateFullName:(fullName)=>{},
    updateEmailConfirmed:(value)=>{},
});

const checkLoggedInStatus = () =>{
    const user = auth.currentUser;
    console.log('line 14, user', user);
    return user;
}




const AuthContextProvider = ({children}) =>{
    
    const user = checkLoggedInStatus();
    
    const [isLoggedIn, setIsLoggedIn] = useState(user ? true: false);
    const [fullNameIsSet, setFullNameIsSet] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState(user && user.emailVerified);
    const [authEmail, setAuthEmail] = useState(user ? user.email : null);
    const [authUserId, setAuthUserId] = useState(user ? user.uid: null);
    const [userName, setUserName] = useState('');



    const login = (email, uid, fullName, emailIsConfirmed, fullNameSet) =>{
        console.log('line 25, logging in');
        setIsLoggedIn(true);
        setAuthUserId(uid);
        setAuthEmail(email);
        setUserName(fullName);
        setEmailConfirmed(emailIsConfirmed);
        setFullNameIsSet(fullNameSet);
    };

    

    const logout = () => {
        console.log('line 34, logging out');
        setAuthEmail('');
        setIsLoggedIn(false);
        setAuthUserId(null);
        
      };

      const updateConfirmEmail = (value) =>{
        setEmailConfirmed(value);
      };

      const updateFullName = (fullName) =>{
        console.log('line 64', fullName);
        setUserName(fullName);
        setFullNameIsSet(true);
      };

      const updatePassword = (newPassowrd) =>{

      }

      const checkEmailConfirmed = () =>{
        console.log(user.emailVerified);
        return(emailConfirmed);
      };

      const value = {
        fullName:userName,
        uid:authUserId,
        email:authEmail,
        loggedIn:isLoggedIn,
        hasFullName:fullNameIsSet,
        confirmedEmail:emailConfirmed,
        login:login,
        logout:logout,
        checkEmailConfirmed:checkEmailConfirmed,
        updateFullName:updateFullName,
        updateEmailConfirmed:updateConfirmEmail,
      };

      return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthContextProvider;
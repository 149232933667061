import styles from "./EquipmentLists.module.css";
import { readEquipmentList, deleteEquipList, addEquipmentList, editEquipList } from "../../firebase/equipment";
import { useState } from "react";
import { useEffect } from "react";
import editLogo from "../../assets/images/edit.png";
import deleteLogo from "../../assets/images/delete.png";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import backBtn from "../../assets/images/arrow_left.png";
import CustomerMenu from "../../components/customerMenu/CustomerMenu";
import { useNavigate } from "react-router-dom";
import addBtn from "../../assets/images/add.png";
import MessageBox from "../../components/messageBox/MessageBox";
import AddNewCategory from "../../components/addNewCategory/AddNewCategory";
import EditCategoryName from "../../components/editCategoryName/EditCategoryName";
import Title from "../../components/title/Title";

const EquipmentLists = () =>{

    const [equipmentList, setEquipmentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showMsgDelete, setShowMsgDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const [fbError, setFbError] = useState(false);
    const [fbMessage, setFbMessage] = useState('');
    const [deleteItem, setDeleteItem] = useState('');
    const [showAddNewList, setShowAddNewList] = useState(false);
    // const [iDToAdd, setIdToAdd] = useState('');
    const [iDToEdit, setIdToEdit] = useState('');
    const [showEditListName, setShowEditListName] = useState(false);
    const [listName, setListName] = useState('');

    const navigation = useNavigate();
    
    const deepCopyArray = (sourceList) => {

        console.log(sourceList);
        // Initialize
        let newList = [];
    
        // Make sure that there is something to copy
        if (sourceList && (sourceList.length > 0)) {
            for (const sourceData of sourceList) {
                let tempData = {
                    serverId: sourceData.serverId,
                    name: sourceData.name,
                };
                console.log(tempData);
                newList.push(tempData);
            };
        };
    
        // Return value to caller
        console.log(newList);
        return newList;
    }

    const readData=async()=>{
        setLoading(true);
        setError(false);
        let isError = false;
        let response;
        try{
            response = await readEquipmentList();
            if (response.hasError){
                isError = true;
            }
        }
        catch(err){
            isError = true;
        };
        if (isError){
            //TODO error handling
            setError(true);
        }
        else{
            console.log(response.equipmentLists);
            setEquipmentList(deepCopyArray(response.equipmentLists));
        }
        setLoading(false);
    };

    useEffect(()=>{
        readData();
    },[]);
    
    
    const onEditGetUpdate = (data) =>{
        setIdToEdit(data.serverId);
        setShowEditListName(true);
        setListName(data.name)
    };

    const onEdit = async(newName) =>{
        setShowEditListName(false);
        setLoading(true);
        setFbError(false);
        setFbMessage('');
        console.log(newName);
        let response;
        const serverId = iDToEdit;
        console.log(serverId);
        try{
            response= await editEquipList(newName, serverId);
            console.log(response);
            if(response.hasError){
                console.log(response.hasError);
                setFbError(true);
                setFbMessage("There was a problem updating the equipment list name. Please try again.")
            }
            else{
                let tempArray = [];
                for (const data of equipmentList){
                    let tempData;
                    if (data.serverId === serverId)
                    {
                        tempData = {
                            serverId: serverId,
                            name: newName,
                        };
                    }
                    else{
                        tempData = {
                            serverId: data.serverId,
                            name: data.name,
                        };
                    };
                    tempArray.push(tempData);
                };
                console.log(tempArray);
                setEquipmentList(tempArray);
            };
        }
        catch(err){
            console.log(err);
            setFbError(true);
            setFbMessage("There was a problem updating the equipment list name. Please try again.")
        };
        setLoading(false);
    };


    const confirmDelete=(data)=>{
        setIdToDelete(data.serverId);
        setDeleteItem(data.name.toUpperCase())
        setShowMsgDelete(true);
    };

    const onDelete = async()=> {
        setShowMsgDelete(false);
        setLoading(true);
        setFbError(false);
        setFbMessage('');
        let response;
        const serverId = idToDelete;
        try{
            response= await deleteEquipList(serverId);
            console.log(response);
            if(response.hasError){
                setFbError(true);
                setFbMessage("There was a problem deleting the equipment list. Please try again.")
            }
            else{
                let tempArray = [];
                for (const data of equipmentList){
        
                    if (data.serverId !== serverId){
                        let tempData = {
                            serverId: data.serverId,
                            name: data.name,
                        };
                        tempArray.push(tempData);
                    }
                };
                setEquipmentList(tempArray);
                console.log(tempArray);
            }
        }
        catch(err){
            setFbError(true);
            setFbMessage("There was a problem deleting the equipment list. Please try again.")
        };
        setLoading(false);
    };

    const cancelDelete = () =>{
        setDeleteItem('');
        setShowMsgDelete(false);
    };

    const handleUser = () =>{
        navigation("/profile");
    };

    const onHelp = () =>{
        navigation("/help");
    };

    const handleSettings = () =>{
        navigation("/settings");
    };

    const handleGoBack = ()=>{
        navigation("/settings");
    };
    const gotoCustomer = () =>{
        navigation("/customer");
    };

    const handleListAdd = async(name) =>{
        console.log(name);
        setFbError(false);
        setFbMessage('');
        let response;
        try{
            response= await addEquipmentList(name);
            console.log(response);
            if(response.hasError){
                console.log(response.hasError);
                setFbError(true);
                setFbMessage("There was a problem adding the new equipment list. Please try again.")
            }
            else{
                let tempArray = [];
                for (const data of equipmentList){
                    let tempData = {
                        serverId: data.serverId,
                        name: data.name,
                    }
                    tempArray.push(tempData);
                };
                const newData = {
                    serverId:response.data.serverId,
                    name:response.data.name,
                };
                tempArray.push(newData);
                setEquipmentList(tempArray);
            };
        }
        catch(err){
            console.log(err);
            setFbError(true);
            setFbMessage("There was a problem adding the new equipment list. Please try again.")
        };
        setShowAddNewList(false);
    };


 
    return(
        <div className={styles.container}>
            <CustomerMenu showHelp={true} showBack={true} showSettings={true} onGoBack={handleGoBack} 
                onUser={handleUser} showUser={true} onHelp={onHelp} onSettings={handleSettings} gotoCustomerPage={gotoCustomer}/>
            <Title titleText={"Maintain Equipment Lists"}/>   
            {showEditListName && <EditCategoryName category={listName} msg={`Update equipment list name:`} onSave={onEdit} onCancel={()=>{setShowEditListName(false)}}/>}
            {showAddNewList && <AddNewCategory msg={"New equipment list name:"} onSave={handleListAdd} onCancel={()=>{setShowAddNewList(false)}}/>}
            {showMsgDelete && <MessageBox  
                info={`Warning! This action will delete the Equipment List ${deleteItem}. Do you really want to delete this?`}
                mainBtnHandler={onDelete} cancelBtnHandler={cancelDelete} showMain={true} showCancel={true}/>}
            {loading && <LoadingSpinner showText={true}/>}
            {error && <div className={styles.errorContainer}><p className={styles.error}>There was a problem reading the data. Please try again.</p></div>}
            {fbError &&  <div className={styles.errorContainer}><p className={styles.error}>{fbMessage}</p></div>}

            {!loading && 
                <div className={styles.rowContainer}>
                    <div className={styles.topBtnContainer}>
                        <div onClick={()=>{setShowAddNewList(true)}}><img src={addBtn} className={styles.addBtn} alt = "Add Button"/></div>
                    </div>
                </div>
            }
            
            {equipmentList.map((data)=>(
                <div className={styles.list} key={data.serverId}>
                    <div className={styles.listItem} >
                        <div className={styles.nameContainer}>
                            <p className={styles.listLabel}>{data.name}</p>
                        </div>
            
                        <div className={styles.logoContainer}>
                            <img src={editLogo} className={styles.logo} alt="Edit Button" onClick={()=>{onEditGetUpdate(data)}} />
                            <img src={deleteLogo} className={styles.logo} alt="Delete Button" onClick={()=>{confirmDelete(data)}}/>
                        </div>
                        
                    </div>
                </div>
            ))}
        </div>
    )
};
export default EquipmentLists;
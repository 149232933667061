import styles from "./ThreeBtns.module.css";
import Button from "../button/Button";

const ThreeBtns = ({onBtnLeft, btnLeftTitle, enableBtnLeft, onBtnCenter, btnCenterTitle, enableBtnCenter, onBtnRight,  btnRightTitle, enableBtnRight}) => {
  return (
    <div className={styles.btnContainer}>
      <Button onClick={onBtnLeft} title={btnLeftTitle} enableBtn={enableBtnLeft} size="small" />
      <Button title={btnCenterTitle} onClick={onBtnCenter} enableBtn={enableBtnCenter} size="small"/>
      <Button title={btnRightTitle} onClick={onBtnRight} enableBtn={enableBtnRight} size="small"/>
    </div>
  );
};

export default ThreeBtns;

import styles from "./HeaderSmall.module.css";
import logo from "../../assets/images/full-logo-transparent-1950x1142.png";
import { useNavigate } from "react-router-dom";

const HeaderSmall = () => {

  const navigation = useNavigate();
  const handleClick = () =>{
    navigation("/");
  };

  return (
    <div className={styles.container}>
      <div onClick={handleClick}>
        <img className={styles.logo} src={logo} alt="BillyQR Logo"/>
        <p className={styles.tagline}>{`[service record storage]`}</p>
      </div>
    </div>
  );
};

export default HeaderSmall;

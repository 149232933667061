import styles from "./HeaderLogo.module.css";
import logo from "../../assets/images/full-logo-transparent-1950x1142.png";
import { useNavigate } from "react-router-dom";

const HeaderLogo = () => {

  const navigation = useNavigate();
  const handleClick = () =>{
    navigation("/");
  };

  return (
    <div className={styles.container}>
      <div onClick={handleClick}>
        <img className={styles.logo} src={logo} alt="BillyQR Logo"/>
      </div>
    </div>
  );
};

export default HeaderLogo;

import styles from "./TwoBtns.module.css";
import Button from "../button/Button";

const TwoBtns = ({onBtnLeft, btnLeftTitle, btnRightTitle, onBtnRight, enableBtnLeft, enableBtnRight}) => {
  return (
    <div className={styles.btnContainer}>
      <Button onClick={onBtnLeft} title={btnLeftTitle} enableBtn={enableBtnLeft}/>
      <Button title={btnRightTitle} onClick={onBtnRight} enableBtn={enableBtnRight}/>
    </div>
  );
};

export default TwoBtns;


import {auth} from "../firebase/config";
import { fireDB } from "../firebase/config";
import { collection, getDoc, doc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";


export const useLogin = () =>{
    const login = async (email, password)=>{
        let errorMsg = null;
        let fullName='';
        let res;
        try {
            res = await signInWithEmailAndPassword(auth, email, password);
        }
        catch (err){
            const errorMsg = err;
            return {error:true, msg:errorMsg};
        };
        errorMsg = null;
        let result;
        let emailConfirmed = false;
        try{
            result = await getDoc(doc(fireDB, "users", res.user.uid));
            console.log('line 25', result.data());
            if (result.exists()){
                fullName = result.data().fullName;
                emailConfirmed = result.data().isEmailVerified;
            };
        }
        catch(error){
            const errorMsg = error;
            return{error:true, msg:errorMsg}
        };
        return {error:false, email:res.user.email, uid:res.user.uid, fullName:fullName, emailConfirmed:emailConfirmed};
    };  
    return {login};
};
import styles from "./CreateUserProfile.module.css";
import { useState } from "react";
import HeaderSmall from "../../components/headerSmall/HeaderSmall";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { updateUserName } from "../../firebase/user";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";


const CreateUserProfile = () =>{

    const [fullName, setFullName] = useState('');
    const [showContinue, setShowContinue] = useState(false);
    const {uid,updateFullName} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [onError, setOnError] = useState(false);
    

    const navigation=useNavigate();

    const handleFullName=(e)=>{
        setFullName(e.target.value);
        if (e.target.value.length >0 && !showContinue){
            setShowContinue(true);
        };
    };

    const handleContinue = async() =>{
        setLoading(true);
        setOnError(false);
        console.log(uid);
        let hasError;
        try{
            hasError = await updateUserName(uid, fullName);
            if (!hasError){
                updateFullName(fullName);
                console.log('after update FullName');
                setLoading(false);
                navigation("/customer");
            }
            else{
                setOnError(true);
                setLoading(false);
            }
            
        }       
        catch(err){
            setLoading(false);
            setOnError(true);
        };
    };
    
    return(
        <div className={styles.container}>
            <HeaderSmall/>
            <div className={styles.infoContainer}>
                <p className={styles.infoText}>BillyQR needs to know a little bit about you. Please fill in the information here and tap the CONTINUE button.</p>
            </div>
            <div className={styles.inputContainer}>
                <p className={styles.label}>Full name:</p>
                <input className={styles.nameInput} type="text" value={fullName} onChange={(e)=>{handleFullName(e)}}/>
            </div>
            <div className={styles.buttonContainer}><Button title="CONTINUE" onClick={handleContinue} enableBtn={!loading && showContinue}/></div>
            {loading && <LoadingSpinner showText={false}/>}
            {onError && <div><p className={styles.error}>Error saving user fullname. Please try again.</p></div>}
        </div>
    )
};
export default CreateUserProfile;
import styles from "./SystemDefaultEquipmentCategories.module.css";
import { readSystemDefaultEquipmentCategories, deleteCategories, addCategory, editCategory } from "../../firebase/equipment";
import { useState } from "react";
import { useEffect } from "react";
import editLogo from "../../assets/images/edit.png";
import deleteLogo from "../../assets/images/delete.png";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import backBtn from "../../assets/images/arrow_left.png";
import CustomerMenu from "../../components/customerMenu/CustomerMenu";
import { useNavigate } from "react-router-dom";
import addBtn from "../../assets/images/add.png";
import MessageBox from "../../components/messageBox/MessageBox";
import AddNewCategory from "../../components/addNewCategory/AddNewCategory";
import EditCategoryName from "../../components/editCategoryName/EditCategoryName";
import Title from "../../components/title/Title";

const SystemDefaultEquipmentCategories = () =>{

    const [dataToShow, setDataToShow] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showBack, setShowBack] = useState(false);
    const [showMsgDelete, setShowMsgDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const [fbError, setFbError] = useState(false);
    const [fbMessage, setFbMessage] = useState('');
    const [deleteCategory, setDeleteCategory] = useState('');
    const [showAddNewCategory, setShowAddNewCategory] = useState(false);
    const [showAddNewCategoryToChild, setShowAddNewCategoryToChild] = useState(false);
    const [iDToAdd, setIdToAdd] = useState('');
    const [parentName, setParentName] = useState('');
    const [iDToEdit, setIdToEdit] = useState('');
    const [showEditCategoryName, setShowEditCategoryName] = useState(false);
    const [categoryName, setCategoryName] = useState('');

    const navigation = useNavigate();
    
    const deepCopyCategoryArray = (sourceCategories) => {

    
        // Initialize
        let newCategories = [];
    
        // Make sure that there is something to copy
        if (sourceCategories && (sourceCategories.length > 0)) {
            for (const sourceCategory of sourceCategories) {
                let newCategory = {
                    id: sourceCategory.id,
                    name: sourceCategory.name,
                    children: deepCopyCategoryArray(
                        sourceCategory.children
                    )
                }
                if (sourceCategory.parentId) {
                    newCategory.parentId = sourceCategory.parentId;
                }
                console.log(newCategory);
                newCategories.push(newCategory);
            }
        }
    
        // Return value to caller
        console.log(newCategories);
        return newCategories;
    
    }

    const readData=async()=>{
        setLoading(true);
        setError(false);
        let isError = false;
        let response;
        try{
            response = await readSystemDefaultEquipmentCategories();
            if (response.hasError){
                isError = true;
            }
        }
        catch(err){
            isError = true;
        };
        if (isError){
            //TODO error handling
            setError(true);
        }
        else{
            console.log(response.categories);
            setDataToShow(deepCopyCategoryArray(response.categories));
        }
        setLoading(false);
    };

    useEffect(()=>{
        readData();
    },[]);


    const handleCategoryClick = (category) =>{
        console.log('arrow clicked', category);
        if (category.children && category.children.length > 0){
            const tempData = [];
            for(const data of dataHistory){
                tempData.push(deepCopyCategoryArray(data));
            };
            tempData.push(deepCopyCategoryArray(dataToShow));
            console.log(tempData);
            setDataHistory(tempData);
            setDataToShow(deepCopyCategoryArray(category.children));
            setShowBack(true);
        };
    };
    

    const onGoBack = () =>{
        console.log(dataHistory);
        console.log(dataHistory.length);
        if (dataHistory.length === 1){
            setShowBack(false);
        };
        if (dataHistory.length > 0){
            setDataToShow(deepCopyCategoryArray(dataHistory[dataHistory.length-1]));
            const newHistory = dataHistory.slice(0, -1);
            console.log(newHistory);
            setDataHistory(newHistory);
        };  
    };
    
    const onEditGetUpdate = (data) =>{
        setIdToEdit(data.id);
        setShowEditCategoryName(true);
        setCategoryName(data.name)
    };

    const onEdit = async(newName) =>{
        setShowEditCategoryName(false);
        setLoading(true);
        setFbError(false);
        setFbMessage('');
        console.log(newName);
        let response;
        const id = iDToEdit
        try{
            response= await editCategory(id, newName);
            console.log(response);
            if(response.hasError){
                console.log(response.hasError);
                setFbError(true);
                setFbMessage("There was a problem updating the category name. Please try again.")
            }
            else{
                setDataToShow(deepCopyCategoryArray(response.data));
            }
        }
        catch(err){
            console.log(err);
            setFbError(true);
            setFbMessage("There was a problem updating the category name. Please try again.")
        };
        setLoading(false);
    }


    const confirmDelete=(data)=>{
        setIdToDelete(data.id);
        setDeleteCategory(data.name.toUpperCase())
        setShowMsgDelete(true);
    };

    const onDelete = async()=> {
        setShowMsgDelete(false);
        setLoading(true);
        setFbError(false);
        setFbMessage('');
        let response;
        const id = idToDelete
        try{
            response= await deleteCategories(id);
            console.log(response.hasError, response.data);
            if(response.hasError){
                setFbError(true);
                setFbMessage("There was a problem deleting the category (and subcategories). Please try again.")
            }
            else{
                setDataToShow(deepCopyCategoryArray(response.data));
            }
        }
        catch(err){
            setFbError(true);
            setFbMessage("There was a problem deleting the category (and subcategories). Please try again.")
        };
        setLoading(false);
    };

    const cancelDelete = () =>{
        setDeleteCategory('');
        setShowMsgDelete(false);
    };

    const handleUser = () =>{
        navigation("/profile");
    };

    const onHelp = () =>{
        navigation("/help");
    };

    const handleSettings = () =>{
        navigation("/settings");
    };

    const handleGoBack = ()=>{
        navigation("/settings");
    };
    const gotoCustomer = () =>{
        navigation("/customer");
    };

    const handleCategoryAddToLevel = async(category) =>{
        console.log(category);
        setFbError(false);
        setFbMessage('');
        let response;
        const id = dataToShow[0].parentId;
        try{
            response= await addCategory(id, category);
            console.log(response);
            if(response.hasError){
                console.log(response.hasError);
                setFbError(true);
                setFbMessage("There was a problem adding the new category. Please try again.")
            }
            else{
                setDataToShow(deepCopyCategoryArray(response.data));
            }
        }
        catch(err){
            console.log(err);
            setFbError(true);
            setFbMessage("There was a problem adding the new category. Please try again.")
        };
        setShowAddNewCategory(false);
    };

    const handleDataForAddToChild = (data)=>{
        setIdToAdd(data.id);
        setParentName(data.name)
        setShowAddNewCategoryToChild(true);
    };

    const handleCategoryAddToChild = async(category) =>{
        setFbError(false);
        setFbMessage('');
        let response;
        const id = iDToAdd
        try{
            response= await addCategory(id, category);
            console.log(response);
            if(response.hasError){
                console.log(response.hasError);
                setFbError(true);
                setFbMessage("There was a problem adding the new category. Please try again.")
            }
            else{
                setDataToShow(deepCopyCategoryArray(response.data));
            }
        }
        catch(err){
            console.log(err);
            setFbError(true);
            setFbMessage("There was a problem adding the new category. Please try again.")
        };
        setShowAddNewCategoryToChild(false);
    }

 
    return(
        <div className={styles.container}>
            <CustomerMenu showHelp={true} showBack={true} showSettings={true} onGoBack={handleGoBack} 
                onUser={handleUser} showUser={true} onHelp={onHelp} onSettings={handleSettings} gotoCustomerPage={gotoCustomer}/>
            <Title titleText={"Maintain System Default Equipment Categories"}/>
            {showEditCategoryName && <EditCategoryName category={categoryName} msg={`Update category name:`} onSave={onEdit} onCancel={()=>{setShowEditCategoryName(false)}}/>}
            {showAddNewCategory && <AddNewCategory msg={"New category:"} onSave={handleCategoryAddToLevel} onCancel={()=>{setShowAddNewCategory(false)}}/>}
            {showAddNewCategoryToChild && <AddNewCategory msg={`New category under category ${parentName}:`} onSave={handleCategoryAddToChild} onCancel={()=>{setShowAddNewCategoryToChild(false)}}/>}
            {showMsgDelete && <MessageBox  
                info={`Warning! This action will delete the category ${deleteCategory} and all subcategories. Do you really want to delete this?`}
                mainBtnHandler={onDelete} cancelBtnHandler={cancelDelete} showMain={true} showCancel={true}/>}
            {loading && <LoadingSpinner showText={true}/>}
            {error && <div className={styles.errorContainer}><p className={styles.error}>There was a problem reading the data. Please try again.</p></div>}
            {fbError &&  <div className={styles.errorContainer}><p className={styles.error}>{fbMessage}</p></div>}

            {!loading && !showBack && 
                <div className={styles.rowContainer}>
                    <div className={styles.topBtnContainer}>
                        <div><img src={backBtn} className={styles.noBackBtn} alt="Back Button"/></div>
                        <div onClick={()=>{setShowAddNewCategory(true)}}><img src={addBtn} className={styles.addBtn} alt = "Add Button"/></div>
                    </div>
                </div>
            }
            {!loading && showBack && 
                <div className={styles.categpories}>
                    <div className={styles.rowContainer}>
                    <div  className={styles.topBtnContainer}>
                        <div onClick={onGoBack}><img src={backBtn} className={styles.backBtn} alt="Back Button"/></div>
                        <div onClick={()=>{setShowAddNewCategory(true)}}><img src={addBtn} className={styles.addBtn} alt = "Add Button"/></div>
                    </div>
                    </div>
                </div>
            }
            
            {dataToShow.map((data)=>(
                <div className={styles.categories} key={data.id}>
                    <div className={styles.categoryItem} >
                        
                        {(data.children && data.children.length >0) &&
                        <div className={styles.nameContainer} onClick={()=>{handleCategoryClick(data)}}>
                            <p className={styles.categoryLabel}>{data.name}</p>
                            <p>{` > `}</p>
                            {(!data.children || data.children.length === 0) && <p>{` + `}</p>}                            
                        </div>}
                        {(!data.children || data.children.length === 0) &&
                        <div className={styles.nameContainer} onClick={()=>{handleDataForAddToChild(data)}}>
                            <p className={styles.categoryLabel}>{data.name}</p>
                            <p>{` + `}</p>                           
                        </div>}
                        <div className={styles.logoContainer}>
                            <img src={editLogo} className={styles.logo} alt="Edit Button" onClick={()=>{onEditGetUpdate(data)}} />
                            <img src={deleteLogo} className={styles.logo} alt="Delete Button" onClick={()=>{confirmDelete(data)}}/>
                        </div>
                        
                    </div>
                </div>
            ))}
        </div>
    )
};
export default SystemDefaultEquipmentCategories;
import styles from "./UserProfile.module.css";
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
import Button from "../../components/button/Button";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import { useLogout } from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import CustomerMenu from "../../components/customerMenu/CustomerMenu";
import editIcon from "../../assets/images/edit.png";
import EditProfileName from "../../components/editProfileName/EditProfileName";
import { updateUserName } from "../../firebase/user";
import Title from "../../components/title/Title";

const UserProfile = () =>{
    const navigation = useNavigate();
    const authCtx = useContext(AuthContext);
    const [fullName, setFullName] = useState(authCtx.fullName);
    const uid = authCtx.uid;
    const [showEditName, setShowEditName] = useState(false);
    const [showError, setShowError] = useState(false);
    const {logout} = useLogout();

    const handleGoBack = () =>{
        navigation(-1);
    };

    const handleLogout = () =>{
        logout();
        authCtx.logout();
        navigation("/");
    };

    const handleResetPassword=()=>{
        navigation("/reset-password");
    };

    const onHelp=()=>{
        navigation("/help");
    };

    const handleSettings = () =>{
        navigation("/settings");
    };

    const gotoCustomerPage = () =>{
        navigation("/");
    };

    const handleEdit = () =>{
        setShowError(false);
        setShowEditName(true);
    };
    

    const onCancelNameChange = () =>{
        setShowEditName(false);
    };

    const onSaveNameChange = async(newName) =>{
        console.log(newName);
        let isError;
        try{
            isError = await updateUserName(uid,newName);
            if (isError){
                console.log('error');
                setShowError(true);
            }
            else{
                setFullName(newName);
                authCtx.updateFullName(newName);
            };
        }
        catch(err){
            setShowError(true);
            console.log(err);
        }
        setShowEditName(false);
    };

    return(
        <div className={styles.container}>
            <CustomerMenu gotoCustomerPage={gotoCustomerPage} showHelp={true} showBack={true} onGoBack={handleGoBack} onHelp={onHelp} showSettings={true} onSettings={handleSettings}/>
            <Title titleText={"User Profile"}/>
            <div className={styles.profileContainer}>
                <p className={styles.label}>{`Hello, ${fullName}!`}</p>
                {showError && <div><p className={styles.error}>Error updating user full name. Please try again.</p></div>}
                <div className={styles.nameContainer}>
                    <p className={styles.label}>{`Full name: ${fullName}`}</p>
                    <div onClick={handleEdit}><img className={styles.logo} src={editIcon} alt="Edit Name"/></div>
                </div>
                
                {showEditName && <EditProfileName onSave={onSaveNameChange}  onCancel={onCancelNameChange} fullName={fullName}/>}
                <Button title="RESET PASSWORD" onClick={handleResetPassword} enableBtn={true}/>
                <Button title="LOGOUT" onClick={handleLogout} enableBtn={true}/>
            </div>
        </div>
    )
}
export default UserProfile;
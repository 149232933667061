
import {auth} from "../firebase/config";
import { signOut } from "firebase/auth";

export const useLogout = () =>{

    const logout = async () => {
        try{
          const res = await signOut(auth);
          console.log('line 9', res);
          return {msg:'success', error:false};
        }
        catch (err){
          console.log('error on logout: ' + err);
          return{msg:'error on logout' + err, error:true};
        };
      };

      return {logout};
      
};
import styles from "./EditProfileName.module.css";
import { useState } from "react";
import TwoBtns from "../twoBtns/TwoBtns";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

const EditProfileName = ({fullName, onSave, onCancel}) =>{
    console.log('line 6', fullName);
    
    const [newName, setNewName] = useState(fullName);
    const [enableSave, setEnableSave] = useState(false);
    const [onLoading, setOnLoading] = useState(false);
    const [enableCancel, setEnableCancel] = useState(true);

    const onNameChange=(e)=>{
        const canSave = enableSave;
        if (!canSave && e.target.value.length >0){
            setEnableSave(true);
        };
        setNewName(e.target.value);
    };

    const onSaveName = () =>{
        setOnLoading(true);
        setEnableSave(false);
        setEnableCancel(false);
        onSave(newName);
    };


    return (
        <div className={styles.overlay}>
          <div className={styles.container}>
            <div className={styles.inputContainer}>
                <p className={styles.label}>Update full name:</p>
                <input className={styles.nameInput} type="text" value={newName} onChange={(e)=>{onNameChange(e)}}/>
            </div>
            <div className={styles.btnContainer}>
                {onLoading && <LoadingSpinner showText={false}/>}
                <TwoBtns onBtnLeft={onSaveName} btnLeftTitle={'SAVE'} btnRightTitle={'CANCEL'} onBtnRight={onCancel} 
                    enableBtnLeft={enableSave} enableBtnRight={enableCancel}/>
            </div>
          </div>
        </div>
      );
};
export default EditProfileName;
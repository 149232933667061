import LandingPage from "./screens/landingPage/LandingPage";
import Login from "./screens/login/Login";
import CustomerHome from "./screens/customerHome/CustomerHome";
import ResetPassword from "./screens/resetPassword/ResetPassword";
import Register from "./screens/register/Register";
import ConfirmEmail from "./screens/confirmEmail/ConfirmEmail";
import "./App.css";
import { Route, Routes, BrowserRouter, Navigate} from "react-router-dom";
import CreateUserProfile from "./screens/createUserProfile/CreateUserProfile";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import PageNotFound from "./screens/pageNotFound/PageNotFound";
import { useEffect } from "react";
import ResetPasswordConfirmation from "./screens/resetPasswordConfirmation/ResetPasswordConfirmation";
import UserProfile from "./screens/userProfile/UserProfile";
import Help from "./screens/help/Help";
import Settings from "./screens/settings/Settings";
import SystemDefaultEquipmentCategories from "./screens/systemDefaultEquipmentCategories/SystemDefaultEquipmentCategories";
import EquipmentLists from "./screens/equipmentLists/EquipmentLists";

function App() {

  const authCtx = useContext(AuthContext);
  const loggedIn = authCtx.loggedIn;
  const confirmedEmail = authCtx.confirmedEmail;
  const hasFullName = authCtx.hasFullName;

  // useEffect(()=>{
  //   loggedIn = authCtx.loggedIn;
  //   console.log(loggedIn);
  // },[])
  

  return (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route 
          exact path="/"
          element={loggedIn ? <Navigate to="/customer" /> : <LandingPage/>}
          // element={<LandingPage/>}
          // element={loggedIn && confirmedEmail && hasFullName ? <Navigate to="/customer" /> : 
          //   (loggedIn && confirmedEmail && !hasFullName ? <Navigate to="/create-user-profile"/> : 
          //   (loggedIn && !confirmedEmail ? <Navigate to="/confirm-email"/> : <LandingPage/>)) }
        />
        <Route
          path="/login"
          //element={<Login/>}
          element={loggedIn ? <Navigate to="/customer"/> : <Login/>}
        />
        <Route
          path="/customer"
          // element={<CustomerHome/>}
          element={!loggedIn ? <Navigate to="/"/> : <CustomerHome/>}
        />
         <Route
          path="/reset-password"
          element={<ResetPassword/>}
        />
         <Route
          path="/register-account"
          element={<Register/>}
        />
        <Route
          path="/confirm-email"
          element={<ConfirmEmail/>}
        />
        <Route 
          path="/create-user-profile"
          element={<CreateUserProfile/>}
        />
        <Route
          path="/reset-password-confirmation"
          element={<ResetPasswordConfirmation/>}
        />
        <Route
          path="/profile" element={<UserProfile/>}
        />
        <Route
          path="/help" element={<Help/>}
        />
        <Route
          path="/settings" element={<Settings/>}
        />
        <Route
          path="/default-equipment-categories" element={<SystemDefaultEquipmentCategories/>}
        />
        <Route 
          path="/equipment-lists" element={<EquipmentLists/>}
        />
        <Route
          path="*" element={<PageNotFound/>}
        />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;

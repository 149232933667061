import styles from "./Prices.module.css";
import PriceRow from "../priceRow/PriceRow";

const priceTableInfo = [
    {id:1, column1:"", column2:"Free", column3:"Basic", column4:"Intermediate", column5:"Pro", column6:"Custom"},
    {id:2, column1:"Cost", column2:"$0", column3:"$6.99/mo.", column4:"$12.99/mo.", column5:"$15.99/mo.", column6:"Call For Pricing"},
    {id:3, column1:"View Most Recent Service Record", column2:"x", column3:"x", column4:"x", column5:"x", column6:"x"},
    {id:4, column1:"View All Service Records", column2:"", column3:"x", column4:"x", column5:"x", column6:"x"},
    {id:5, column1:"Create Record", column2:"", column3:"x", column4:"x", column5:"x", column6:"x"},
    {id:6, column1:"Customize Service Records", column2:"", column3:"x", column4:"x", column5:"x", column6:"x"},
    {id:7, column1:"Create Custom Lists", column2:"", column3:"x", column4:"x", column5:"x", column6:"x"},
    {id:8, column1:"Print QR Codes (Add Equipment) +$219 Bluetooth Printer Charge", column2:"", column3:"10", column4:"100", column5:"1000", column6:">1000"},
    {id:9, column1:"Access to Service Record Templates", column2:"", column3:"", column4:"x", column5:"x", column6:"x"},
    {id:10, column1:"Add Pictures", column2:"", column3:"", column4:"x", column5:"x", column6:"x"},
    {id:11, column1:"Manage Data from Desktop Version", column2:"", column3:"", column4:"x", column5:"x", column6:"x"},
    {id:12, column1:"Automatically Email Customer When Service is Due", column2:"", column3:"", column4:"", column5:"x", column6:"x"},
    {id:13, column1:"Email Service Record in PDF", column2:"", column3:"", column4:"", column5:"x", column6:"x"},
]

const Prices = () =>{
    
    return(
        <div className={styles.container}>

            {priceTableInfo.map((row)=>(<PriceRow key={row.id} item={row}/>))}
        </div>
    )
};
export default Prices;
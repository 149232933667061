import { cloudFuncs } from "./config";
import { httpsCallable } from "firebase/functions";
import { getDoc, doc } from "firebase/firestore";
import { fireDB } from "./config";

const changePasswordToUser =async(verificationCode, newPassword) => {
    console.log('logged in line 5');

    let hasError = false;
    const fn = httpsCallable(cloudFuncs, "changeLoggedInUserPassword");
        try{
            const success = await fn({verificationCode:verificationCode, newPassword:newPassword});
            console.log(success.data.success);
            if (!success.data.success){
                // TODO error
                hasError=true;
            
            }
            else{
                //    TODO success
                
            };
        }
        catch(err){
            console.log('logged in error', err);
           hasError=true;
        };
    console.log(hasError);
    return(hasError);
};
const changePasswordToEmail =async(emailAddress, verificationCode, newPassword) => {
    let hasError = false;
    const fn = httpsCallable(cloudFuncs, "changeLoggedOutUserPassword");
        try{
            const response = await fn({emailAddress:emailAddress, verificationCode:verificationCode, newPassword:newPassword});
            console.log(response.data.success);
            if (!response.data.success){
                // TODO error
                console.log('has error');
                hasError=true;
            
            }
            else{
                //    TODO success
                console.log('no error');
                
            };
        }
        catch(err){
           hasError=true;
           console.log('not logged in error', err);
        };

return(hasError);
};

const resetEmailToUser = async() =>{
    let hasError = false;
    console.log('reset email to user');
    const fn = httpsCallable(cloudFuncs, "sendEmailVerificationCodeToUser");
            try{
                const success = await fn();
                console.log(success);
                if (!success){
                    // TODO error
                    hasError=true;
                
                }
                else{
                    //    TODO success
                    
                };
            }
            catch(err){
               hasError=true;
            };
    console.log(hasError);
    return(hasError);
};

const resetEmailToEmail = async(emailAddress) =>{
    console.log(emailAddress);
    let hasError = false;
    const fn = httpsCallable(cloudFuncs, "sendVerificationCodeToEmailAddress");
            try{
                const response = await fn({emailAddress:emailAddress});
                console.log(response);
                if (response.data.error){
                    // TODO error
                    hasError=true;
                
                }
                else{
                    //    TODO success
                   
                };
            }
            catch(err){
                hasError=true;
            };

    return(hasError);
};

const verifyCode = async(verificationCode) =>{
    let result = true;
    const fn = httpsCallable(cloudFuncs,"verifyUserEmailAddress");
    try{
        const response = await fn({verificationCode});
        console.log(response.data.success);
        if(!response.data.success){
            //TODO error
            result=false;
        }
        else{
            //TODO success
            result=true;
        };
    }
    catch(err){
        //TODO error
        result = false;
    };
    console.log(result);
    return result;
}

const updateUserName = async(uid, fullName)=>{
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"updateUser");
    let response;

    try{
        response = await fn({uid, fullName});
        console.log(response);
    }
    catch(err){
        console.log(err);
        hasError = true;
    };
   
    return hasError;
}

const readUserFullName = async (id) =>{

    console.log('line 138', id);
    let result;
    let fullName = '';
    try{
        result = await getDoc(doc(fireDB, "users", id));
        console.log(result);
        if (result.exists()){
            fullName = result.data().fullName;
            console.log(fullName);
            return(fullName);
        };
    }
    catch(error){
        const errorMsg = error;
        return('');
    };
};



export {resetEmailToEmail, resetEmailToUser, verifyCode, updateUserName, changePasswordToEmail, changePasswordToUser, readUserFullName};
import styles from "./ConfirmEmail.module.css";
import { useState } from "react";
import TwoBtns from "../../components/twoBtns/TwoBtns";
import HeaderSmall from "../../components/headerSmall/HeaderSmall";
import { verifyCode, resetEmailToUser } from "../../firebase/user";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";


const ConfirmEmail = () =>{
    const authCtx = useContext(AuthContext);
    const [code, setCode] = useState('');
    const [onError, setOnError] = useState(false);
    const [onLoading, setOnLoading] = useState(false);
    const [enableResend, setEnableResend] = useState(true);
    const [enableVerify, setEnableVerify] = useState(true);
    const [onFBError, setOnFBError] = useState(false);
    const [fbMessage, setFBMessage] = useState('');

    const navigation=useNavigate();
    
    const handleVerify = async() =>{
        setOnFBError(false);
        setEnableResend(false);
        setEnableVerify(false);
        setOnLoading(true);
        setOnError(false);
        setFBMessage('');
        const result = await verifyCode(code);
        console.log(result);
        if (result){
            //TODO handle register complete
            setOnLoading(false);
            setEnableResend(true);
            setEnableVerify(true);
            console.log("Success");
            authCtx.updateEmailConfirmed(true);
            navigation("/create-user-profile");
        }
        else{
            setEnableResend(true);
            setEnableVerify(true);
            setOnLoading(false);
            setOnError(true);
            console.log("Failure to verify email address");
        };
    };

    const handleCode=(e)=>{
        setCode(e.target.value);
    };

    const onResend = async() =>{
        setOnLoading(true);
        setEnableResend(false);
        setEnableVerify(false);
        setOnFBError(false);
        setOnError(false);
        setFBMessage('');
        let error;
            try{
                error = await resetEmailToUser();
                console.log(error);
                if (error){
                    setOnLoading(false);
                    setEnableResend(true);
                    setEnableVerify(true);
                    setOnFBError(true);
                    setFBMessage('There was an problem resending the code. Please try again.');
                }
                else{
                    setOnLoading(false);
                    setEnableResend(true);
                    setEnableVerify(true);
                };
            }
            //TODO handle error
            catch(err){
                setOnLoading(false);           
                setEnableResend(true);
                setEnableVerify(true);
                setOnFBError(true);
                setFBMessage('There was an problem resending the code. Please try again.');
            };

        
    };

    
    return(
        <div className={styles.container}>
            <HeaderSmall/>
            <div className={styles.infoContainer}>
                <p className={styles.infoText}>BillyQR needs to verify your email address. Please enter the code that your received in your email.</p>
                <p className={styles.infoText}>If you don't see the email in your inbox, please check your spam folder. Tap the 
                    RESEND CODE button to get another code.
                </p>
            </div>
            <div className={styles.inputContainer}>
                <p className={styles.label}>Code:</p>
                <input className={styles.codeInput} type="text" value={code} onChange={(e)=>{handleCode(e)}}/>
            </div>
            {onLoading && <LoadingSpinner showText={false}/>}
            {onFBError && <div className={styles.errorContainer}><p className={styles.error}>{fbMessage}</p></div>}
            {onError && <div className={styles.errorContainer}><p className={styles.error}>Failure to verify email address. Please try again.</p></div>}
            <TwoBtns onBtnLeft={handleVerify} btnLeftTitle={"VERIFY"} enableBtnLeft={enableVerify} enableBtnRight={enableResend} onBtnRight={onResend} btnRightTitle={"RESEND CODE"}/>

        </div>
    )
};
export default ConfirmEmail;
import { cloudFuncs } from "./config";
import { httpsCallable } from "firebase/functions";
import { getDoc, doc } from "firebase/firestore";
import { fireDB } from "./config";

const readEquipmentList = async () =>{
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"getAllActiveEquipmentLists");
    let response;

    try{
        response = await fn();
        console.log(response);
    }
    catch(err){
        console.log(err);
        hasError = true;
    };
   
    return ({hasError:hasError, equipmentLists:response.data.equipmentLists});
}

const readSystemDefaultEquipmentCategories = async()=>{
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"getSystemDefaultEquipmentCategories");
    let response;

    try{
        response = await fn();
        console.log(response);
    }
    catch(err){
        console.log(err);
        hasError = true;
    };
   
    return ({hasError:hasError, categories:response.data.equipmentCategories});
};

const deleteEquipList = async(serverId) =>{
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"deleteEquipmentList");
    let response;
    try{
        response = await fn({serverId:serverId});
        console.log(response);
        if (!response.data.resultCode ==="SUCCESS"){
            hasError = true;
        }
    }
    catch(err){
        hasError = true;
    };
    return({hasError:hasError, data:response.data.newCategories})
};

const deleteCategories = async(id)=>{
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"deleteSystemDefaultEquipmentCategoryAndChildren");
    let response;
    try{
        response = await fn({id:id});
        console.log(response);
        if (!response.data.resultCode ==="SUCCESS"){
            hasError = true;
        }
    }
    catch(err){
        hasError = true;
    };
    return({hasError:hasError, data:response.data.newCategories})
}

const addEquipmentList = async (name)=>{
    console.log(name);
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"createEquipmentList");
    let response;
    try{
        response = await fn({name:name});
        console.log(response);
        if (!response.data.resultCode ==="SUCCESS"){
            hasError = true;
        }
    }
    catch(err){
        hasError = true;
    };
    console.log(hasError, response.data.equipmentList);
    return({hasError:hasError, data:response.data.equipmentList})
}

const addCategory = async(id, category)=>{
    console.log(id, category);
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"addSystemDefaultEquipmentCategory");
    let response;
    try{
        response = await fn({parentId:id, name:category});
        console.log(response);
        if (!response.data.resultCode ==="SUCCESS"){
            hasError = true;
        }
    }
    catch(err){
        hasError = true;
    };
    console.log(hasError, response.data.newCategories);
    return({hasError:hasError, data:response.data.newCategories})
}

const editEquipList = async(name, serverId) =>{
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"updateEquipmentList");
    let response;
    console.log(serverId,name);
    try{
        response = await fn({serverId:serverId, name:name});
        console.log(response);
        if (!response.data.resultCode ==="SUCCESS"){
            hasError = true;
        };
    }
    catch(err){
        hasError = true;
    };
    return({hasError:hasError});
};

const editCategory = async(id, category)=>{
    console.log(id, category);
    let hasError = false;
    const fn = httpsCallable(cloudFuncs,"updateSystemDefaultEquipmentCategory");
    let response;
    try{
        response = await fn({id:id, newName:category});
        console.log(response);
        if (!response.data.resultCode ==="SUCCESS"){
            hasError = true;
        };
    }
    catch(err){
        hasError = true;
    };
    console.log(hasError, response.data.newCategories);
    return({hasError:hasError, data:response.data.newCategories});
};

export {readEquipmentList, readSystemDefaultEquipmentCategories, deleteCategories, addCategory, editCategory, 
    addEquipmentList, editEquipList, deleteEquipList};

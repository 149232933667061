import styles from "./Login.module.css";
import HeaderSmall from "../../components/headerSmall/HeaderSmall";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import visible from "../../assets/images/visibility.png";
import notVisible from "../../assets/images/visibility_off.png";
import TwoBtns from "../../components/twoBtns/TwoBtns";
import { useLogin } from "../../hooks/useLogin";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";


const Login = () =>{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);
    const [enableLogin, setEnableLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const {login} = useLogin();
    const authCtx = useContext(AuthContext);
    

    const navigation = useNavigate();
    const toggleVisible = () =>{
        setShowPassword((prev)=>!prev);
    }

    const handleLogin = async() =>{
         // login to fb auth
         setLoading(true);
         console.log(email, password);
         const res = await login(email, password);
        
         if (res.error){
            console.log(res.msg);
            setShowError(true);
            setLoading(false);
         }
         else{
             const fullNameSet = res.fullName && res.fullName.length > 0;
             // update auth context to show logged in
             console.log(res.email, res.uid, res.fullName, res.emailConfirmed, fullNameSet);
             authCtx.login(res.email,res.uid, res.fullName, res.emailConfirmed, fullNameSet);
             setLoading(false);
             navigation('/customer');
         };
    };

    const checkEnableLogin = (from, currentValue) =>{
        let show = false;
        if (from === 'email'){
            const currentPassword = password;
            if (currentValue !==null && currentValue.length >= 3 && currentValue.includes('@') && currentPassword !== null && currentPassword.length >= 8){
                show = true;
            } 
        }
        else {
            const currentEmail = email;
            if (currentValue !== null && currentValue.length >=8 && currentEmail !== null && currentEmail.length >= 3 && currentEmail.includes('@')){
                show = true;
            }
        }
        return(show);
    };

    const handleEmail=(e) =>{
        if (showError){setShowError(false);};
        const currentEmail = e.target.value;
        setEmail(e.target.value);
        const enableLoginBtn = checkEnableLogin('email',currentEmail);
        setEnableLogin(enableLoginBtn);

    };

    const handlePassword=(e) =>{
        if (showError){setShowError(false);};
        const currentPassword = e.target.value;
        setPassword(e.target.value);
        const enableLoginBtn = checkEnableLogin('password',currentPassword);
        setEnableLogin(enableLoginBtn);
    };

    const onPasswordReset = () =>{
        navigation("/reset-password");
    };

    const handleRegister = () =>{
        navigation("/register-account");
    };

    return(
        <div className={styles.container}>
            <HeaderSmall/>
            <p className={styles.label}>Welcome to BillyQR!</p>
            <div className={styles.inputContainer}>
                <p className={styles.label}>Email address:</p>
                <input className={styles.emailInput} type="text" value={email} onChange={(e)=>{handleEmail(e)}}/>
            </div>
            <div className={styles.inputContainer}>
                <p className={styles.label}>Password:</p>
                <div className={styles.row}>
                    <input className={styles.passwordInput} type={showPassword ? "password": "text"} value={password} onChange={(e)=>{handlePassword(e)}}/>
                    {showPassword && <img className={styles.logo} src={visible} alt="Show Password Icon" onClick={toggleVisible}/>}
                    {!showPassword && <img className={styles.logo} src={notVisible} alt="Hide Password Icon" onClick={toggleVisible}/>}
                </div>
            </div>  
            <div className={styles.linkContainer}>
                <p className={styles.passwordLink} onClick={onPasswordReset}>Forgot password?</p>
            </div>
            <TwoBtns onBtnLeft={handleLogin} btnLeftTitle={"LOGIN"} enableBtnLeft={!loading && enableLogin} onBtnRight={handleRegister} btnRightTitle={"REGISTER"} enableBtnRight={!loading}/>
            {loading && <LoadingSpinner showText={false}/>}
            {showError && 
            <div>
                <p className={styles.error}>Error logging in. Please verify your email and password, and try again.</p>
            </div>
            }

        </div>
    )
}
export default Login;
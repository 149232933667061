import styles from "./Button.module.css";

const Button=({title, onClick, enableBtn,size})=>{
    return( 
        <>
        {size === 'small' &&
        <div className={enableBtn ? styles.btnSmall : styles.btnSmallDisable}>
            <p className={enableBtn? styles.btnTextSmall : styles.btnTextSmallDisable} onClick={enableBtn ? onClick : ()=>{}}>{title}</p>
        </div>
        }
        {size !== 'small' &&
        <div className={enableBtn ? styles.btn : styles.btnDisable}>
            <p className={enableBtn? styles.btnText : styles.btnTextDisable} onClick={enableBtn ? onClick : ()=>{}}>{title}</p>
        </div>
        }
        </>
    );
};

export default Button;
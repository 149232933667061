
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
import styles from "./ResetPasswordConfirmation.module.css";
import { useState } from "react";
import visible from "../../assets/images/visibility.png";
import notVisible from "../../assets/images/visibility_off.png";
import TwoBtns from "../../components/twoBtns/TwoBtns";
import ThreeBtns from "../../components/threeBtns/ThreeBtns";
import { useNavigate } from "react-router-dom";
import infoIcon from "../../assets/images/infoIcon.png";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { resetEmailToEmail, resetEmailToUser } from "../../firebase/user";
import { changePasswordToEmail, changePasswordToUser } from "../../firebase/user";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import MessageBox from "../../components/messageBox/MessageBox";

const ResetPasswordConfirmation = () =>{

    const navigation = useNavigate();
    const {loggedIn, logout} = useContext(AuthContext);
    const location = useLocation();
    const [showInfo, setShowInfo] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [enableChangePassword, setEnableChangePassword] = useState(false);
    const [isError, setIsError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [code, setCode] = useState('');
    const [fbMessage, setFBMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [enableResendCode,setEnableResendCode] = useState(true);
    const [codeMessage, setCodeMessage] = useState(false);
    const [enableCancel, setEnableCancel] = useState(true);
    const [showPasswordChangeConfirmation, setShowPasswordChangeConfirmation] = useState(false);

    const checkPasswords = (password1, password2) =>{
        if(password1 === password2){
            setIsError(false);
        }
        else{
            setIsError(true);
        };
    };

    const handlePassword=(e) =>{
        const currentPassword = e.target.value;
        const currentPasswordTwo = passwordTwo;
        setPassword(e.target.value);
        checkPasswords(currentPassword, currentPasswordTwo);
        const enableResetPassword = checkEnableResetPassword('password',currentPassword);
        setEnableChangePassword(enableResetPassword);
    };
    
    const handlePasswordTwo=(e) =>{
        const currentPasswordTwo = e.target.value;
        const currentPassword = password;
        setPasswordTwo(e.target.value);
        checkPasswords(currentPassword, currentPasswordTwo);
        const enableResetPassword = checkEnableResetPassword('passwordTwo',currentPasswordTwo);
        setEnableChangePassword(enableResetPassword);
    };



    const toggleVisible = () =>{
        setIsVisible((prev)=>!prev);
    };

    const toggleInfoVisible = () =>{
        setShowInfo((prev)=>!prev);
    };


    const toggleVisible2 = () =>{
        setIsVisible2((prev)=>!prev);
    };

    const checkEnableResetPassword = (from, currentValue) =>{
        let show = false;
        if (from === 'code'){
            const currentPassword = password;
            const currentPasswordTwo = passwordTwo;
            console.log(currentPassword, currentPasswordTwo, currentPassword===currentPasswordTwo);
            if (currentPassword === currentPasswordTwo && currentValue !==null && currentValue.length == 6  && 
                currentPassword !== null && currentPassword.length >= 8){
                show = true;
            } 
        }
        else if (from === 'password'){
            const currentCode = code;
            const currentPasswordTwo = passwordTwo;
            if (currentValue === currentPasswordTwo && currentValue !== null && currentValue.length >=8 && currentCode !== null && currentCode.length == 6 ){
                show = true;
            }
        }
        else if (from === 'passwordTwo'){
            const currentCode = code;
            const currentPasswordOne = password;
            if (currentValue === currentPasswordOne && currentValue !== null && currentValue.length >=8 && currentCode !== null && currentCode.length == 6 ){
                show = true;
            }

        }
        console.log(show);
        return(show);
    };


    const handleChangePassword = async() =>{
        // if logged in
        const userCode = code;
        const newPassword = password;
        setLoading(true);
        setFBMessage('');
        setEnableCancel(false);
        setEnableChangePassword(false);
        setEnableResendCode(false);
        setIsError(false);
        setCodeMessage(false);
        console.log('line 119');
    
        if (loggedIn){
            let error;
            try{
                error = await changePasswordToUser(userCode, newPassword);
                console.log(error);
                if(error){
                    setLoading(false);
                    setFBMessage('There was a problem changing your password. Please try again');
                    setEnableCancel(true);
                    setEnableChangePassword(true);
                    setEnableResendCode(true);
                }
                else{
                    setLoading(false);
                    setShowPasswordChangeConfirmation(true);
                }
            }
            //TODO handle error
            catch(err){
                setLoading(false);
                setEnableCancel(true);
                setEnableChangePassword(true);
                setEnableResendCode(true);
                setFBMessage('There was a problem changing your password. Please try again');
            };
        }
        // if not logged in
        else{
            const userEmail = location.state.userEmail;
            let error;
            try{
                error = await changePasswordToEmail(userEmail, userCode, newPassword);
                console.log(error);
                if (error){
                    setLoading(false);
                    setEnableCancel(true);
                    setEnableChangePassword(true);
                    setEnableResendCode(true);
                    setFBMessage('There was a problem changing your password. Please try again');
                }
                else{
                    setLoading(false);
                    setShowPasswordChangeConfirmation(true);
                }
            }
            //TODO handle error
            catch(err){
                setLoading(false);
                setEnableCancel(true);
                setEnableChangePassword(true);
                setEnableResendCode(true);
                setFBMessage('There was a problem changing your password. Please try again');
            };
        };
    };


    const handleResendCode = async() =>{

        let changePasswordBtnStatus = enableChangePassword;
        setFBMessage('');
        setIsError(false);
        setLoading(true);
        setEnableResendCode(false);
        if (changePasswordBtnStatus){setEnableChangePassword(false);};
        setEnableCancel(false);
        setCodeMessage(false);
        console.log(loggedIn);

        // logged in
        if (loggedIn) {
            let error;
            try{
                error = await resetEmailToUser();
                console.log(error);
                if (error){
                    setLoading(false);
                    setEnableCancel(true);
                    if (changePasswordBtnStatus){setEnableChangePassword(true);};
                    setEnableResendCode(true);
                    setFBMessage('There was an problem resending the code. Please try again.');
                }
                else{
                    setLoading(false);
                    setEnableCancel(true);
                    if (changePasswordBtnStatus){setEnableChangePassword(true);};
                    setEnableResendCode(true);
                    setCodeMessage(true);
                };
            }
            //TODO handle error
            catch(err){
                setLoading(false);           
                setEnableCancel(true);
                if (changePasswordBtnStatus){setEnableChangePassword(true);};
                setEnableResendCode(true);         
                setFBMessage('There was an problem resending the code. Please try again.');
            };

        }
        // not logged in
        else{
            const userEmail = location.state.userEmail;
            console.log(userEmail);
            let error;
            try{
                error = await resetEmailToEmail(userEmail);
                console.log(error);
                if (error){
                    setLoading(false);
                    setEnableCancel(true);
                    if (changePasswordBtnStatus){setEnableChangePassword(true);};
                    setEnableResendCode(true);
                    setFBMessage('There was an problem resending the code. Please try again.');
                }
                else{
                    setEnableCancel(true);
                    if (changePasswordBtnStatus){setEnableChangePassword(true);};
                    setEnableResendCode(true);
                    setLoading(false);
                    setCodeMessage(true);
                };
            }
            //TODO handle error
            catch(err){
                setLoading(false); 
                setEnableCancel(true);
                if (changePasswordBtnStatus){setEnableChangePassword(true);};
                setEnableResendCode(true);                   
                setFBMessage('There was an problem resending the code. Please try again.');
            };
        };

    };

    const handleCode = (e) =>{
        const currentCode = e.target.value;
        setCode(e.target.value);
        if (codeMessage) {setCodeMessage('');};
        const enableResetPassword = checkEnableResetPassword('code',currentCode);
        setEnableChangePassword(enableResetPassword);
    };

    const handleCancel = () =>{
        if (loggedIn) {navigation("/customer");}
        else{navigation("/login");};
    };


    const onChangedPasswordSuccessfully = () =>{
        setShowPasswordChangeConfirmation(false);
        logout();
        navigation("/login");
    };

    


    return(
        <div className={styles.container}>
            <HeaderLogo/>
            {showPasswordChangeConfirmation && 
                <MessageBox  info={`Your password has been changed. You will need to login again to continue using BillyQR.`}
                mainBtnHandler={onChangedPasswordSuccessfully} cancelBtnHandler={()=>{}} showCancel={false} showMain={true}/>
            }
            <div className={styles.infoContainer}>
                <p className={styles.info}>BillyQR has sent a verification code to your email address. Please enter that code below.</p>
                <p className={styles.info}>If you do not see the email in your inbox, please check your spam folder. Tap the RESEND CODE button below to get another code.</p>
            </div>
            <div className={styles.inputContainer}>
                <div><p className={styles.label2}>Verification Code:</p></div>
                <input className={styles.codeInput} type="text" value={code} onChange={(e)=>{handleCode(e)}}/>
            </div>
            <div className={styles.inputContainer}>
                <div className={styles.passwordRow}>
                    <div><p className={styles.label}>Password:</p></div>
                    <div><img className={styles.infoIcon} src={infoIcon} alt="Information Icon" onClick={toggleInfoVisible}/></div>
                </div>
                {showInfo && <p className={styles.infoText}>Enter at least 8 characters.</p>}
                <div className={styles.row}>
                    <input className={styles.passwordInput} type={isVisible ? "text" : "password"} value={password} onChange={(e)=>{handlePassword(e)}}/>
                    {isVisible && <img className={styles.logo} src={notVisible} alt="Hide Password Icon" onClick={toggleVisible}/>}
                    {!isVisible && <img className={styles.logo} src={visible} alt="Show Password Icon" onClick={toggleVisible}/>}
                </div>
            </div>  
            <div className={styles.inputContainer}>
                <p className={styles.label2}>Re-enter password:</p>
                <div className={styles.row}>
                    <input className={styles.passwordInput} type={isVisible2 ? "text" : "password"} value={passwordTwo} onChange={(e)=>{handlePasswordTwo(e)}}/>
                    {isVisible2 && <img className={styles.logo} src={notVisible} alt="Hide Password Icon" onClick={toggleVisible2}/>}
                    {!isVisible2 && <img className={styles.logo} src={visible} alt="Show Password Icon" onClick={toggleVisible2}/>}
                </div>
            </div>  
            {isError && <p className={styles.error}>Passwords do not match.</p>}
            {fbMessage && <p className={styles.error}>{fbMessage}</p>}
            {codeMessage && <p className={styles.noError}>A new code has been sent to your email. Please enter it above.</p>}
            {loading && <LoadingSpinner showText={false}/>}
            <div className={styles.btnContainer}>
                <ThreeBtns onBtnLeft={handleChangePassword} btnLeftTitle={"CHANGE PASSWORD"} enableBtnLeft={enableChangePassword} 
                onBtnCenter={handleResendCode} btnCenterTitle={"RESEND CODE"} enableBtnCenter={enableResendCode}
                onBtnRight={handleCancel} btnRightTitle="CANCEL" enableBtnRight={enableCancel}/>
            </div>
            
        </div>
    )

};
export default ResetPasswordConfirmation;
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAYnv2-s0h1x3KWSrIo6iyWcTXH__n3AA8",
    authDomain: "billy-qr.firebaseapp.com",
    projectId: "billy-qr",
    storageBucket: "billy-qr.firebasestorage.app",
    messagingSenderId: "56701952273",
    appId: "1:56701952273:web:c7554a8bad88a70fb5ecb0",
    measurementId: "G-K72ER8LT0L"
  };

// init firebase
const app = initializeApp(firebaseConfig);

// init firestore developlment database
const fireDB = getFirestore(app);

// init firestore auth
const auth = getAuth(app);

// init firebase functions
const cloudFuncs = getFunctions(app);

await setPersistence(auth, browserLocalPersistence);

export {fireDB, auth, cloudFuncs};
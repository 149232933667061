import styles from "./LandingPage.module.css";
import Header from "../../components/header/Header";
import ComingSoon from "../../components/comingsoon/ComingSoon";
import TwoBtns from "../../components/twoBtns/TwoBtns";
import ImgGallery from "../../components/imgGallery/ImgGallery";
import Prices from "../../components/prices/Prices";
import { useNavigate } from "react-router-dom";
const LandingPage = () => {

  const navigation = useNavigate();

  const handleLogin = () =>{
      navigation('/login');
  };
  
  const handleRegister=()=>{
    navigation("/register-account");
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <ComingSoon />
        <Header />
      </div>
      <div className={styles.info}>
        <p className={styles.infoText}>
          BillyQR is an app (available on iOS and Android devices) that manages
          assets by allowing users to collect, store and share service records
          for equipment.
        </p>
      </div>
      <TwoBtns onBtnLeft={handleLogin} btnLeftTitle={"LOGIN"} enableBtnLeft={true} onBtnRight={handleRegister} btnRightTitle={"REGISTER"} enableBtnRight={true}/>
      <ImgGallery/>
      <Prices/>
    </div>
  );
};
export default LandingPage;

import styles from "./CustomerHome.module.css";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {readUserFullName} from "../../firebase/user";
import ConfirmEmail from "../confirmEmail/ConfirmEmail";
import CreateUserProfile from "../createUserProfile/CreateUserProfile";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import CustomerMenu from "../../components/customerMenu/CustomerMenu";
import Title from "../../components/title/Title";


const CustomerHome = () =>{

    const navigation=useNavigate();
    const authCtx = useContext(AuthContext);
    console.log(authCtx.loggedIn);
    const [fullNameConfirmed, setFullNameConfirmed] = useState(false);
    const [fullName, setFullName] = useState('');
    const [emailConfirmed, setEmailConfirmed] = useState(authCtx.confirmedEmail);
    const [isLoading, setIsLoading] = useState(true);

    
    useEffect(()=>{
        setIsLoading(true);
        const getName = async (uid) => {
            const res = await readUserFullName(uid);    
            console.log(res);
            if (res){
                authCtx.updateFullName(res);
                setFullName(res);
                setFullNameConfirmed(true);
            };
        };
        getName(authCtx.uid);
        console.log('line 31',fullName);
        setIsLoading(false);
    },[authCtx.uid, authCtx, fullName,authCtx.confirmedEmail])

    const handleUser = () =>{
        navigation("/profile");
        setIsLoading(true);
    };

    const onHelp=()=>{
        navigation("/help");
    };

    const handleSettings=()=>{
        navigation("/settings");
    };

    return(
        <>
        {isLoading && <div><p>Customer Home</p><LoadingSpinner showText={true}/></div>}
        {!isLoading && !emailConfirmed && <ConfirmEmail/>}
        {!isLoading && emailConfirmed && !authCtx.hasFullName && <CreateUserProfile/>}
        {!isLoading && emailConfirmed && authCtx.hasFullName &&
        (<div className={styles.container}>
            <CustomerMenu showHelp={true} showBack={false} showSettings={true} onUser={handleUser} showUser={true} onHelp={onHelp} onSettings={handleSettings}/>
            <Title titleText={'Customer Home'}/>
            <div className={styles.titleContainer}>
                <p className={styles.label}>{`Hello, ${fullName}!`}</p>
            </div>
            
        </div>)
        }
        </>
    );
};

export default CustomerHome;
import styles from "./Help.module.css";
import CustomerMenu from "../../components/customerMenu/CustomerMenu";
import { useNavigate } from "react-router-dom";
import Title from "../../components/title/Title";
const Help = ({}) =>{

    const navigation=useNavigate();

    const onGoBack=()=>{
        navigation(-1);
    };

    const handleUser  = () =>{
        navigation("/profile");
    };

    const handleSettings = () =>{
        navigation("/settings");
    };

    const gotoCustomerPage = () =>{
        navigation("/");
    };

    return(
        <div className={styles.container}>
            <CustomerMenu gotoCustomerPage={gotoCustomerPage} showBack={true} onUser={handleUser}  showHelp={false} onGoBack={onGoBack} showUser={true} showSettings={true} onSettings={handleSettings}/>
            <Title titleText={'About'}/>
            <div className={styles.helpContainer}>
                <p className={styles.label}>Website version 1.0</p>
                <p className={styles.label}>More information coming soon!</p>              
            </div>
        </div>
    );
};

export default Help;
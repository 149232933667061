import styles from "./Register.module.css";
import { useState } from "react";
import visible from "../../assets/images/visibility.png";
import notVisible from "../../assets/images/visibility_off.png";
import TwoBtns from "../../components/twoBtns/TwoBtns";
import { useNavigate } from "react-router-dom";
import HeaderSmall from "../../components/headerSmall/HeaderSmall";
import { createUserWithEmailAndPassword} from "firebase/auth";
import { auth } from "../../firebase/config";
import infoIcon from "../../assets/images/infoIcon.png";
import { sendEmailCode } from "../../firebase/user";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

const Register = () =>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [enableRegister, setEnableRegister] = useState(false);
    const [isError, setIsError] = useState(false);
    const [fbMessage, setFBMessage] = useState('');
    const [isFBMessage, setIsFBMessage] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigation = useNavigate();
    const authCtx = useContext(AuthContext);
    const authFoo=null;

    const toggleVisible = () =>{
        setIsVisible((prev)=>!prev);
    };

    const toggleInfoVisible = () =>{
        setShowInfo((prev)=>!prev);
    };


    const toggleVisible2 = () =>{
        setIsVisible2((prev)=>!prev);
    };

    const checkEnableRegister = (from, currentValue) =>{
        let show = false;
        if (from === 'email'){
            const currentPassword = password;
            const currentPasswordTwo = passwordTwo;
            console.log(currentPassword, currentPasswordTwo, currentPassword===currentPasswordTwo);
            if (currentPassword === currentPasswordTwo && currentValue !==null && currentValue.length >= 3 && currentValue.includes('@') && 
                currentPassword !== null && currentPassword.length >= 8){
                show = true;
            } 
        }
        else if (from === 'password'){
            const currentEmail = email;
            const currentPasswordTwo = passwordTwo;
            if (currentValue === currentPasswordTwo && currentValue !== null && currentValue.length >=8 && currentEmail !== null && currentEmail.length >= 3 && currentEmail.includes('@')){
                show = true;
            }
        }
        else if (from === 'passwordTwo'){
            const currentEmail = email;
            const currentPasswordOne = password;
            if (currentValue === currentPasswordOne && currentValue !== null && currentValue.length >=8 && currentEmail !== null && currentEmail.length >= 3 && currentEmail.includes('@')){
                show = true;
            }

        }
        return(show);
    };


    const handleEmail=(e) =>{
        const currentEmail = e.target.value;
        setEmail(e.target.value);
        const enableRegisterBtn = checkEnableRegister('email',currentEmail);
        setEnableRegister(enableRegisterBtn);

    };
    const handleRegister=async()=>{
        setIsLoading(true);
        setIsFBMessage(false);
        
        let result;
        try{
            result = await createUserWithEmailAndPassword(auth, email, password);
            console.log(result);
            console.log(result.user.uid);
            const emailConfirmed = false;
            const fullNameSet = false;
            const fullName = '';
            authCtx.login(result.user.uid, email, fullName, emailConfirmed, fullNameSet);
            setIsLoading(false);
            navigation("/customer");
        }
        catch(err){
            setIsLoading(false);
            setFBMessage("Error attempting to create user account. Please try again.");
            setIsFBMessage(true);
        };
    };

    const onCancel=()=>{
        navigation(-1);
    }

    const checkPasswords = (password1, password2) =>{
        if(password1 === password2){
            setIsError(false);
        }
        else{
            setIsError(true);
        };
    };

    const handlePassword=(e) =>{
        const currentPassword = e.target.value;
        const currentPasswordTwo = passwordTwo;
        setPassword(e.target.value);
        checkPasswords(currentPassword, currentPasswordTwo);
        const enableRegisterBtn = checkEnableRegister('password',currentPassword);
        setEnableRegister(enableRegisterBtn);
    };
    
    const handlePasswordTwo=(e) =>{
        const currentPasswordTwo = e.target.value;
        const currentPassword = password;
        setPasswordTwo(e.target.value);
        checkPasswords(currentPassword, currentPasswordTwo);
        const enableRegisterBtn = checkEnableRegister('passwordTwo',currentPasswordTwo);
        setEnableRegister(enableRegisterBtn);
    };


    return(
        <div className={styles.container}>
             <HeaderSmall/>
            <div className={styles.titleSection}>
                <p className={styles.label}>Thank you for your interest in BillyQR!</p>
                <p className={styles.label}>Tell us a little about yourself and create a password.</p>
            </div>
            <div className={styles.inputContainer}>
                <p className={styles.label}>Email address:</p>
                <input className={styles.emailInput} type="text" value={email} onChange={(e)=>{handleEmail(e)}}/>
            </div>
            <div className={styles.inputContainer}>
                <div className={styles.passwordRow}>
                    <div><p className={styles.label}>Password:</p></div>
                    <img className={styles.infoIcon} src={infoIcon} alt="Information Icon" onClick={toggleInfoVisible}/>
                </div>
                {showInfo && <p className={styles.infoText}>Enter at least 8 characters.</p>}
                <div className={styles.row}>
                    <input className={styles.passwordInput} type={isVisible ? "text" : "password"} value={password} onChange={(e)=>{handlePassword(e)}}/>
                    {isVisible && <img className={styles.logo} src={notVisible} alt="Hide Password Icon" onClick={toggleVisible}/>}
                    {!isVisible && <img className={styles.logo} src={visible} alt="Show Password Icon" onClick={toggleVisible}/>}
                </div>
            </div>  
            <div className={styles.inputContainer}>
                <p className={styles.label}>Re-enter password:</p>
                <div className={styles.row}>
                    <input className={styles.passwordInput} type={isVisible2 ? "text" : "password"} value={passwordTwo} onChange={(e)=>{handlePasswordTwo(e)}}/>
                    {isVisible2 && <img className={styles.logo} src={notVisible} alt="Hide Password Icon" onClick={toggleVisible2}/>}
                    {!isVisible2 && <img className={styles.logo} src={visible} alt="Show Password Icon" onClick={toggleVisible2}/>}
                </div>
            </div>  
            {isError && <p className={styles.error}>Passwords do not match.</p>}
            {isLoading && <LoadingSpinner showText={false}/>}
            <div className={styles.btnContainer}>
                <TwoBtns onBtnLeft={handleRegister} btnLeftTitle={"REGISTER"} enableBtnLeft={isLoading ? false : enableRegister} onBtnRight={onCancel} btnRightTitle={"CANCEL"} enableBtnRight={isLoading ? false : true}/>
            </div>
            {isFBMessage && <p className={styles.error}>{fbMessage}</p>}

        </div>
    )
};
export default Register;
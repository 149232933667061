import styles from "./ResetPassword.module.css";
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
import { useNavigate } from "react-router-dom";
import TwoBtns from "../../components/twoBtns/TwoBtns";
import { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { resetEmailToEmail, resetEmailToUser } from "../../firebase/user";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

const ResetPassword = () =>{

    const navigation = useNavigate();
    const [enableSendBtn, setEnableSendBtn] = useState(false);
    const [email, setEmail] = useState('');
    const {loggedIn} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const checkEnableLogin = (currentValue) =>{
        let show = false;
     
        if (currentValue !==null && currentValue.length >= 3 && currentValue.includes('@') ){
            show = true;
        } 
    
        return(show);
    };


    const handleEmail=(e) =>{
        const currentEmail = e.target.value;
        setEmail(e.target.value);
        const enableLoginBtn = checkEnableLogin(currentEmail);
        setEnableSendBtn(enableLoginBtn);

    };

    const onBack=()=>{
        navigation(-1);
    };

    const onSendCodeNotLoggedIn=async()=>{
        setHasError(false);
        setLoading(true);
        const userEmail = email;
        let error;
        try{
            error = await resetEmailToEmail(userEmail);
            console.log(error);
            if (error){
                setLoading(false);
                setHasError(true);
            }
            else{
                setLoading(false);
                navigation("/reset-password-confirmation",{state:{userEmail:userEmail}});          
            }
        }
        catch(err){
            setLoading(false);
            setHasError(true);
        };
    };

    const onSendCodeLoggedIn=async()=>{
        setHasError(false);
        setLoading(true);
        let error;
        try{
            error = await resetEmailToUser();
            console.log(error);
            if (error){
                setLoading(false);
                setHasError(true);
            }
            else{
                setLoading(false);
                navigation("/reset-password-confirmation",{state:{userEmail:''}});
            }
        }
        catch(err){
            setLoading(false);
            setHasError(true);
        };
    };

    return(
        <div className={styles.container}>
            <HeaderLogo/>
            <div className={styles.row}>
                <p className={styles.label}>Reset Password</p>
            </div>
            {!loggedIn && 
            <>           
                <div className={styles.infoContainer}>
                    <p className={styles.info}>BillyQR will send a verification code to your email address. After you validate the code here you may create a new password.</p>
                </div>
                <div className={styles.inputContainer}>
                    <p className={styles.label}>Email address:</p>
                    <input className={styles.emailInput} type="text" value={email} onChange={(e)=>{handleEmail(e)}}/>
                </div>
                <TwoBtns onBtnLeft={onSendCodeNotLoggedIn} btnLeftTitle={"SEND CODE"} enableBtnLeft={enableSendBtn} onBtnRight={onBack} btnRightTitle={"CANCEL"} enableBtnRight={true}/>
                {loading && <LoadingSpinner showText={false}/>}
                {hasError && <p className={styles.error}>There was a problem sending the code. Please try again.</p>}
            </>
            }
            {loggedIn &&
            <>
                <div className={styles.infoContainer}>
                    <p className={styles.info}>BillyQR will send a verification code to your email address. After you validate the code here you may create a new password.</p>
                </div>
                <TwoBtns onBtnLeft={onSendCodeLoggedIn} btnLeftTitle={"SEND CODE"} enableBtnLeft={!loading ? true: false} onBtnRight={onBack} btnRightTitle={"CANCEL"} enableBtnRight={!loading ? true : false}/>
                {loading && <LoadingSpinner showText={false}/>}
                {hasError && <p className={styles.error}>There was a problem sending the code. Please try again.</p>}
            </>
            }
        </div>
    )
};
export default ResetPassword;